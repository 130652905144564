import { InformationCircleIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { twMerge as mergeClassName } from 'tailwind-merge'

// Components
import { ClientHeader } from '../../components/ClientHeader'
import { CustomLink } from '../../components/CustomLink'
import { DataTable } from '../../components/DataTable'
import { ImportDataChanges } from '../../components/ImportDataChanges'
import { NoDataPrompt } from '../../components/NoDataPrompt'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import { Tooltip } from '../../components/Tooltip'

// Services & Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'
import { getClient } from '../../services/clients.service'
import { getClientDataImports } from '../../services/portfolio.service'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

/**
 *
 * ClientImports
 *
 */
const ClientImports = observer(() => {
  // Context
  const { clientId } = useParams()
  const { client, isImportInProgress, setClient } = useContext(ClientDashboardStoreContext)

  const { pagination, setTotalRecords } = usePagination(10)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('period')
  const { sortedColumn } = sorting

  // State
  const [loadingClient, setLoadingClient] = useState(true)
  const [loadingImports, setLoadingImports] = useState(false)
  const [error, setError] = useState(null)
  const [imports, setImports] = useState([])

  const BASE_IMPORT_URL = `/clients/${clientId}/data-imports/?expand=created_by,last_updated_by,portfolio_data_changes`

  /**
   * Gets the updated list of data imports; updates pagination.
   * @param {string} url
   */
  const getUpdatedDataImports = async (url) => {
    const response = await getClientDataImports(url, setError, setLoadingImports)

    if (response) {
      setTotalRecords(response.count)
      setImports(response.results)
    }
  }

  /**
   * Gets the updated client and data imports.
   */
  const getUpdatedData = async () => {
    getUpdatedDataImports(BASE_IMPORT_URL, setError, setLoadingImports)

    const updatedClient = await getClient(clientId, setError, setLoadingClient)
    if (updatedClient) {
      setClient(updatedClient)
    }
  }

  useEffect(() => {
    if (clientId) {
      getUpdatedData()
    }
  }, [clientId])

  /**
   * When the sort, current page, or row count changes, get the updated list of imports.
   */
  useEffect(() => {
    if (clientId) {
      getUpdatedDataImports(
        `${BASE_IMPORT_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage])

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="flex h-[calc(100vh-150px)] w-full flex-col space-y-4 overflow-y-auto bg-background px-4 pb-12 pt-6 sm:px-6 lg:px-8">
            <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center">
              <h2 className="text-xl font-semibold leading-6 text-gray-900">Data Imports</h2>

              {client?.latestSuccessfulImport !== null && (
                <div className="mt-3 flex flex-row gap-2 sm:ml-4 sm:mt-0">
                  <CustomLink to={`/clients/${clientId}/new-import`}>
                    {isImportInProgress ? 'View Import' : 'Import Data'}
                  </CustomLink>
                </div>
              )}
            </div>

            {client?.latestSuccessfulImport === null ? (
              <NoDataPrompt
                path={`/clients/${clientId}/new-import`}
                title="No Imports Available"
                subtitle={`Please ${
                  client?.pendingDataImport ? 'finish importing' : 'import'
                } portfolio data.`}
                linkText={client?.pendingDataImport ? 'View Pending Import' : 'Import Data'}
              />
            ) : (
              <DataTable
                data={imports}
                columns={[
                  {
                    field: 'status',
                    header: 'Status',
                    body: (row) => {
                      const { status } = row

                      // All other statuses display a badge with a color based on the status
                      let extraClass = 'bg-gray-100 text-grey-800'
                      if (
                        status === 'Pending' ||
                        status === 'Processing' ||
                        status === 'Importing'
                      ) {
                        extraClass = 'bg-yellow-100 text-yellow-800'
                      } else if (status === 'Ready for Review' || status === 'Approved') {
                        extraClass = 'bg-blue-100 text-blue-800'
                      } else if (status === 'Imported') {
                        extraClass = 'bg-green-100 text-green-800'
                      } else if (status === 'Failed') {
                        extraClass = 'bg-red-50 text-red-600'
                      }

                      return (
                        <span
                          className={`inline-flex rounded-full px-2 text-center text-xs font-semibold leading-5 ${extraClass}`}
                        >
                          {status}
                        </span>
                      )
                    },
                    style: { minWidth: '170px' },
                  },
                  {
                    field: 'createdAt',
                    header: 'Created At',
                    body: (row) => dayjs(row.createdAt).format('MM/DD/YYYY HH:mm A'),
                    style: { minWidth: '180px' },
                  },
                  {
                    field: 'modifiedAt',
                    header: 'Modified At',
                    body: (row) => dayjs(row.modifiedAt).format('MM/DD/YYYY HH:mm A'),
                    style: { minWidth: '180px' },
                  },
                  {
                    field: 'createdBy',
                    header: 'Created By',
                    body: (row) => `${row.createdBy?.firstName} ${row.createdBy?.lastName}`,
                    style: { minWidth: '180px' },
                  },
                  {
                    field: 'lastUpdatedBy',
                    header: 'Last Updated By',
                    body: (row) =>
                      `${row.lastUpdatedBy?.firstName} ${row.lastUpdatedBy?.lastName}`,
                    style: { minWidth: '180px' },
                  },
                  {
                    field: 'overrideExistingData',
                    header: 'Override All Data',
                    body: (row) => (
                      <span
                        className={mergeClassName(
                          'inline-flex rounded-full px-2 text-center text-xs font-semibold leading-5',
                          row.overrideExistingData
                            ? 'bg-green-50 text-green-700'
                            : 'bg-blue-50 text-blue-700',
                        )}
                      >
                        {row.overrideExistingData ? 'Yes' : 'No'}
                      </span>
                    ),
                    style: { minWidth: '80px' },
                  },
                  {
                    field: 'periodOverrides',
                    header: 'Period Overrides',
                    body: (row) => (
                      <div className="flex flex-row items-center gap-2">
                        <span
                          className={mergeClassName(
                            'inline-flex rounded-full px-2 text-center text-xs font-semibold leading-5',
                            row.periodOverrides?.length > 0
                              ? 'bg-green-50 text-green-700'
                              : 'bg-blue-50 text-blue-700',
                          )}
                        >
                          {row.periodOverrides?.length > 0 ? 'Yes' : 'No'}
                        </span>

                        {row.periodOverrides?.length > 0 && (
                          <Tooltip
                            content={
                              <div className="w-[190px] rounded-lg bg-white p-4 shadow-lg ring-1 ring-black/5">
                                <span>{_.join(row.periodOverrides, ', ')}</span>
                              </div>
                            }
                            placement="bottom-end"
                          >
                            <InformationCircleIcon className="h-5 text-black sm:h-6" />
                          </Tooltip>
                        )}
                      </div>
                    ),
                    style: { minWidth: '80px' },
                  },
                  {
                    field: 'isHouseholdDataImport',
                    header: 'Household Data',
                    body: (row) => (
                      <span
                        className={mergeClassName(
                          'inline-flex rounded-full px-2 text-center text-xs font-semibold leading-5',
                          row.isHouseholdDataImport
                            ? 'bg-green-50 text-green-700'
                            : 'bg-blue-50 text-blue-700',
                        )}
                      >
                        {row.isHouseholdDataImport ? 'Yes' : 'No'}
                      </span>
                    ),
                    style: { minWidth: '80px' },
                  },
                ]}
                hoverEffect={false}
                loading={loadingImports}
                rowExpansionTemplate={(row) => (
                  <div className="flex flex-col gap-2 p-4">
                    <ImportDataChanges client={client} dataImport={row} />
                  </div>
                )}
                pagination={pagination}
                sorting={sorting}
              />
            )}
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
})

export default ClientImports
