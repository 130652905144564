import React from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'

// Components
import { Button } from '../Button'

const SidePanel = ({ children, open, setOpen, title }) => (
  <Dialog open={open} onClose={setOpen} className="relative z-50">
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-gray-800/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
    />

    <div className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <DialogPanel
            transition
            className="pointer-events-auto w-screen max-w-4xl transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
          >
            <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
              <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                  <DialogTitle className="text-xl font-semibold leading-6 text-gray-900">
                    {title}
                  </DialogTitle>

                  <div className="ml-3 flex h-7 items-center">
                    <Button
                      onClick={() => setOpen(false)}
                      className="-m-2.5 p-2.5"
                      iconOnly
                      icon={<XMarkIcon aria-hidden="true" className="size-6 text-gray-900" />}
                      ariaLabel="Close sidebar"
                    />
                  </div>
                </div>
              </div>

              <div className="relative mt-6 flex flex-1 flex-col gap-4 px-4 sm:px-6">
                {children}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </div>
  </Dialog>
)

SidePanel.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default SidePanel
