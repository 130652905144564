const YES_OR_NO = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

const GROSS_OR_NET = [
  { label: 'Gross', value: 'Gross' },
  { label: 'Net', value: 'Net' },
]

export const DEFAULT_RULES = {
  base: {
    minimumAssets: {
      type: 'number',
      format: 'currency',
      name: 'minimumAssets',
      label: 'Minimum Assets',
    },
    minimumBasedOn: {
      type: 'select',
      name: 'minimumBasedOn',
      label: 'Minimum Based On',
      options: [
        { label: 'Beginning', value: 'Beginning' },
        { label: 'Ending', value: 'Ending' },
        { label: 'Both', value: 'Both' },
      ],
    },
    minimumPolicyMonths: {
      type: 'number',
      format: 'months',
      name: 'minimumPolicyMonths',
      label: 'Minimum Policy',
    },
    scf: {
      type: 'number',
      format: 'percent',
      name: 'scf',
      label: 'SCF',
    },
    scfInflowPolicyMonths: {
      type: 'number',
      format: 'months',
      name: 'scfInflowPolicyMonths',
      label: 'SCF Inflow Policy',
    },
    scfOutflowolicyMonths: {
      type: 'number',
      format: 'months',
      name: 'scfOutflowolicyMonths',
      label: 'SCF Outflow Policy',
    },
    secPerf: {
      type: 'select',
      name: 'secPerf',
      label: 'SEC 1/5/10 Perf',
      options: [
        { label: 'Yes - Annualized', value: 'Yes - Annualized' },
        { label: 'Yes - Cumulative', value: 'Yes - Cumulative' },
        { label: 'Yes - Annualized Cumulative', value: 'Yes - Annualized Cumulative' },
        { label: 'No', value: 'No' },
      ],
    },
    returnTypesShown: {
      type: 'text',
      name: 'returnTypesShown',
      label: 'Return Types Shown',
    },
    nfpModelFee: {
      type: 'number',
      format: 'percent',
      name: 'nfpModelFee',
      label: 'NFP Model Fee',
    },
    modelFee: {
      type: 'number',
      format: 'percent',
      name: 'modelFee',
      label: 'Model Fee',
    },
    statisticsBasedOn: {
      type: 'select',
      name: 'statisticsBasedOn',
      label: 'Statistics Based On',
      options: GROSS_OR_NET,
    },
    currency: {
      type: 'select',
      name: 'currency',
      label: 'Currency',
      options: [
        { label: 'ARS', value: 'ARS' },
        { label: 'AUD', value: 'AUD' },
        { label: 'BRL', value: 'BRL' },
        { label: 'CAD', value: 'CAD' },
        { label: 'CHF', value: 'CHF' },
        { label: 'CNY', value: 'CNY' },
        { label: 'DKK', value: 'DKK' },
        { label: 'EUR', value: 'EUR' },
        { label: 'GBP', value: 'GBP' },
        { label: 'IDR', value: 'IDR' },
        { label: 'ILS', value: 'ILS' },
        { label: 'INR', value: 'INR' },
        { label: 'JPY', value: 'JPY' },
        { label: 'MXN', value: 'MXN' },
        { label: 'MYR', value: 'MYR' },
        { label: 'NOK', value: 'NOK' },
        { label: 'NZD', value: 'NZD' },
        { label: 'PHP', value: 'PHP' },
        { label: 'RUB', value: 'RUB' },
        { label: 'SEK', value: 'SEK' },
        { label: 'THB', value: 'THB' },
        { label: 'TRY', value: 'TRY' },
        { label: 'USD', value: 'USD' },
      ],
    },
    dispersion: {
      type: 'select',
      name: 'dispersion',
      label: 'Disperson',
      options: [
        { label: 'Asset Weighted', value: 'Asset Weighted' },
        { label: 'Equal Weighted', value: 'Equal Weighted' },
      ],
    },
    assetsDisplayedIn: {
      type: 'select',
      name: 'assetsDisplayedIn',
      label: 'Assets Displayed In',
      options: [
        { label: 'Thousands', value: 'Thousands' },
        { label: 'Millions', value: 'Millions' },
        { label: 'Billions', value: 'Billions' },
      ],
    },
    terminated: {
      type: 'select',
      name: 'terminated',
      label: 'Terminated',
      options: YES_OR_NO,
    },
  },
  gips: {
    gipsReportType: {
      type: 'select',
      name: 'gipsReportType',
      label: 'GIPS Report Type',
      options: [
        {
          label: 'Composite Report - Separate Accounts and Pooled Funds',
          value: 'Composite Report - Separate Accounts and Pooled Funds',
        },
        {
          label: 'Composite Report - Separate Accounts Only',
          value: 'Composite Report - Separate Accounts Only',
        },
        { label: 'Pooled Fund Report', value: 'Pooled Fund Report' },
        {
          label: 'Non-GIPS Composite Report - Separate Accounts and Pooled Funds',
          value: 'Non-GIPS Composite Report - Separate Accounts and Pooled Funds',
        },
        {
          label: 'Non-GIPS Composite Report - Separate Accounts Only',
          value: 'Non-GIPS Composite Report - Separate Accounts Only',
        },
        { label: 'Non-GIPS Pooled Fund Report', value: 'Non-GIPS Pooled Fund Report' },
      ],
    },
  },
  pnp: {
    terminationDatePp: {
      type: 'date',
      name: 'terminationDatePp',
      label: 'Termination Date PP',
    },
    strategyTypePp: {
      type: 'select',
      name: 'strategyTypePp',
      label: 'Strategy Type PP',
      options: [
        { label: 'LDPF', value: 'LDPF' },
        { label: 'BDPF', value: 'BDPF' },
        { label: 'Composite', value: 'Composite' },
      ],
    },
    holdingsTypePp: {
      type: 'select',
      name: 'holdingsTypePp',
      label: 'Holdings Type PP',
      options: [
        { label: 'Domestic Only', value: 'Domestic Only' },
        { label: 'Foreign Only', value: 'Foreign Only' },
        { label: 'Domestic and Foreign', value: 'Domestic and Foreign' },
        {
          label: 'Domestic and Foreign Exposure Through Domestic Instruments',
          value: 'Domestic and Foreign Exposure Through Domestic Instruments',
        },
      ],
    },
    appendixCustomPp: {
      type: 'text',
      name: 'appendixCustomPp',
      label: 'Appendix Custom PP',
    },
  },
  advanced: {
    tolerance: {
      type: 'number',
      format: 'percent',
      name: 'tolerance',
      label: 'Tolerance',
    },
    calcPerformance: {
      type: 'select',
      name: 'calcPerformance',
      label: 'Calc Performance',
      options: [
        { label: 'Gross Only', value: 'Gross Only' },
        { label: 'Net Only', value: 'Net Only' },
        { label: 'Both', value: 'Both' },
      ],
    },
    includeInAppendix: {
      type: 'select',
      name: 'includeInAppendix',
      label: 'Include In Appendix',
      options: YES_OR_NO,
    },
    nfpAccountspp: {
      type: 'select',
      name: 'nfpAccountspp',
      label: 'NFP Accounts PP',
      options: [
        { label: 'Include', value: 'Include' },
        { label: 'Exclude', value: 'Exclude' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    perfBasedFees: {
      type: 'text',
      name: 'perfBasedFees',
      label: 'Perf Based Fees',
    },
    creationDate: {
      type: 'date',
      name: 'creationDate',
      label: 'Creation Date',
    },
    inceptionDate: {
      type: 'date',
      name: 'inceptionDate',
      label: 'Inception Date',
    },
    foreignWithholdingTax: {
      type: 'select',
      name: 'foreignWithholdingTax',
      label: 'Foreign Withholding Tax',
      options: GROSS_OR_NET,
    },
    subAdvisor: {
      type: 'select',
      name: 'subAdvisor',
      label: 'Sub-Advisor',
      options: [
        { label: 'No', value: 'No' },
        {
          label: 'A sub-advisor was used for all periods presented',
          value: 'A sub-advisor was used for all periods presented',
        },
        { label: 'Use Customize Column', value: 'Use Customize Column' },
      ],
    },

    noMeaningfulBenchmark: {
      type: 'text',
      name: 'noMeaningfulBenchmark',
      label: 'No Meaningful Benchmark',
    },
    rebalancingFrequency: {
      type: 'select',
      name: 'rebalancingFrequency',
      label: 'Rebalancing Frequency',
      options: [
        { label: 'Daily', value: 'Daily' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
        { label: 'Annually', value: 'Annually' },
      ],
    },
    performanceExaminedFrom: {
      type: 'date',
      name: 'performanceExaminedFrom',
      label: 'Performance Examined From',
    },
    performanceExaminedTo: {
      type: 'date',
      name: 'performanceExaminedTo',
      label: 'Performance Examined To',
    },
    bundledFeeDisclosure: {
      type: 'text',
      name: 'bundledFeeDisclosure',
      label: 'Bundled Fee Disclosure',
    },

    pooledFundInceptionDate: {
      type: 'date',
      name: 'pooledFundInceptionDate',
      label: 'Pooled Fund Inception Date',
    },
    pooledFundExpenses: {
      type: 'text',
      name: 'pooledFundExpenses',
      label: 'Pooled Fund Expenses',
    },

    portabilityDate: {
      type: 'date',
      name: 'portabilityDate',
      label: 'Portability Date',
    },
    portabilityDisclosure: {
      type: 'text',
      name: 'portabilityDisclosure',
      label: 'Portability Disclosure',
    },
    marketingGrowthDrawdown: {
      type: 'text',
      name: 'marketingGrowthDrawdown',
      label: 'Marketing Growth Drawdown',
    },
    compositeDefinition: {
      type: 'textarea',
      name: 'compositeDefinition',
      label: 'Composite Definition',
    },
    leverageOrDerivatives: {
      type: 'textarea',
      name: 'leverageOrDerivatives',
      label: 'Leverage or Derivatives',
    },
    feeScheduleandExpenseRatio: {
      type: 'textarea',
      name: 'feeScheduleandExpenseRatio',
      label: 'Fee Schedule and Expense Ratio',
    },
    modelFeeMethodology: {
      type: 'textarea',
      name: 'modelFeeMethodology',
      label: 'Model Fee Methodology',
    },
    customize: {
      type: 'textarea',
      name: 'customize',
      label: 'Customize',
    },
  },
}
