import { action, observable } from 'mobx'
import { createContext } from 'react'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  client: null,
  viewMyClients: true,
  searchTerm: '',
  search: '',
  first: 0,
  currentPage: 1,
  sortedColumn: '-name',
  sortField: 'name',
  sortOrder: 1,
  perPage: 10,
  totalRecords: 0,
}

const TYPES = {
  client: observable,
  viewMyClients: observable,
  searchTerm: observable,
  search: observable,
  currentPage: observable,
  sortField: observable,
  first: observable,
  perPage: observable,
  sortedColumn: observable,
  totalRecords: observable,
  sortedField: observable,
  sortOrder: observable,
}

export class ClientDashboardStore extends BaseStore {
  get canModifyData() {
    if (!this.client || !this.client.id) return false
    if (
      ['Processing Import', 'Pending Confirmation', 'Importing Data', 'Running CTV6'].includes(
        this.client?.portfolioDataStatus,
      )
    ) {
      return false
    }

    return true
  }

  get canImportData() {
    if (!this.client || !this.client.id) return false
    if (
      ['Processing Import', 'Pending Confirmation', 'Importing Data', 'Running CTV6'].includes(
        this.client?.portfolioDataStatus,
      )
    ) {
      return false
    }

    return true
  }

  get isImportInProgress() {
    if (!this.client || !this.client.id) return false
    if (
      ['Processing Import', 'Pending Confirmation', 'Importing Data'].includes(
        this.client?.portfolioDataStatus,
      )
    ) {
      return true
    }

    return false
  }

  get canRunCTV6() {
    if (!this.client || !this.client.id) return false
    if (
      ['Ready to Run CTV6', 'Import Failed', 'Reports Ready', 'CTV6 Failed'].includes(
        this.client?.portfolioDataStatus,
      )
    ) {
      return true
    }

    return false
  }

  setClient = action((client) => {
    this.client = client
  })

  toggleViewMyClients = action(() => {
    this.currentPage = 1

    // Toggle view last to prevent multiple requests from firing
    this.viewMyClients = !this.viewMyClients
  })

  setSearchTerm = action((term) => {
    this.searchTerm = term
    this.updateSearch()
  })

  setTotalRecords = action((count) => {
    this.totalRecords = count
  })

  updateSearch = action(() => {
    const updatedSearch = this.searchTerm ? `q=${this.searchTerm}` : ''
    this.search = updatedSearch
  })

  setCurrentPage = action((page) => {
    this.currentPage = page
  })

  setSortedColumn = action((column) => {
    this.sortedColumn = column
  })

  setSortField = action((sortField) => {
    this.sortField = sortField
  })

  setSortOrder = action((sortOrder) => {
    this.sortOrder = sortOrder
  })

  setPerPage = action((rows) => {
    this.perPage = rows
  })

  setFirst = action((first) => {
    this.first = first
  })

  handlePageChange = (event) => {
    const newPage = event.first / event.rows + 1
    this.setCurrentPage(newPage)
    this.setFirst(event.first)
    this.setPerPage(event.rows)
  }

  handleSortChange = (event) => {
    const direction = event.sortOrder === 1 ? '' : '-'
    this.setSortedColumn(`${direction}${event.sortField}`)
    this.setSortOrder(event.sortOrder)
    this.setSortField(event.sortField)
    this.setCurrentPage(1)
    this.setFirst(0)
  }
}

export const clientDashboard = new ClientDashboardStore(INITIAL_STATE, TYPES)
export const ClientDashboardStoreContext = createContext(clientDashboard)
