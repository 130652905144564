import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const STATUS_COLORS = {
  'Pending Data': 'bg-red-400/40 text-red-400',

  'Processing Import': 'bg-orange-400/40 text-orange-400',
  'Pending Confirmation': 'bg-orange-400/40 text-orange-400',
  'Importing Data': 'bg-orange-400/40 text-orange-400',
  'Running CTV6': 'bg-orange-400/40 text-orange-400',

  'Import Failed': 'bg-red-400/40 text-red-400',
  'CTV6 Failed': 'bg-red-400/40 text-red-400',

  'Ready to Run CTV6': 'bg-green-400/40 text-green-400',
  'Reports Ready': 'bg-green-400/40 text-green-400',
}

const ClientHeader = ({ client = {} }) => {
  const isImportInProgress = [
    'Processing Import',
    'Pending Confirmation',
    'Importing Data',
  ].includes(client?.portfolioDataStatus)

  if (!client)
    return (
      <nav className="w-full" aria-label="Client Data Management Navigation">
        <section
          style={{ height: 85 }}
          className="flex flex-col border-b border-gray-100 bg-white px-8 py-4 sm:flex-row sm:items-center sm:justify-between"
        ></section>
      </nav>
    )

  return (
    <nav className="w-full" aria-label="Client Data Management Navigation">
      <section className="flex flex-col border-b border-gray-100 bg-white px-8 py-4 sm:flex-row sm:items-center sm:justify-between">
        <div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center gap-x-3">
              {/* Status indicator */}
              <div
                className={`flex-none rounded-full p-1 ${
                  STATUS_COLORS[client.portfolioDataStatus] || 'bg-gray-400/40 text-gray-400'
                }`}
                aria-hidden="true"
              >
                <div className="size-2 rounded-full bg-current" />
              </div>
              {/* Client name */}
              <h1 className="flex flex-wrap items-center gap-x-3 text-base leading-7">
                <Link
                  to={`/clients/${client.id}/dashboard`}
                  className="text-lg font-semibold text-gray-900"
                >
                  {client.name}
                </Link>
                <span className="text-lg text-gray-900">/</span>

                {isImportInProgress ? (
                  <div className="text-sm font-semibold text-gray-900">
                    <Link to={`/clients/${client.id}/new-import`}>
                      {client.portfolioDataStatus}
                    </Link>
                  </div>
                ) : (
                  <div className="text-sm font-semibold text-gray-900">
                    {client.portfolioDataStatus}
                  </div>
                )}
              </h1>
            </div>
          </div>
          <div className="mt-2 flex flex-wrap items-center gap-x-2">
            <span className="tracking-light text-xs font-semibold text-gray-500">
              Date Last Updated:
            </span>
            <span className="text-xs text-gray-500">
              {dayjs(client.modifiedAt).format('MM/DD/YYYY h:mm A')}
            </span>
          </div>
        </div>
      </section>
    </nav>
  )
}

export default ClientHeader

ClientHeader.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
    portfolioDataStatus: PropTypes.string.isRequired,
    modifiedAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
}
