import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { CircleDashed } from 'tabler-icons-react'

// Components
import { CalendarInput } from '../../components/CalendarInput'
import { PageContainer } from '../../components/PageContainer'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextArea } from '../../components/TextArea'
import { TextInput } from '../../components/TextInput'

// Utils & Service
import { getGlobalRules, updateGlobalRules } from '../../services/global.service'
import { toast } from '../../utils/helpers'

// Constants
import { DEFAULT_RULES } from './constants'

const GlobalRules = () => {
  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [savingRules, setSavingRules] = useState(false)

  const handleError = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  const {
    clearErrors,
    control,
    formState: { dirtyFields, errors },
    getValues,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
  })

  useEffect(() => {
    const getUpdatedData = async () => {
      const response = await getGlobalRules(setError, setLoading)

      // Update all rule fields that have options with the matching option object
      const categoryKeys = _.keys(DEFAULT_RULES)
      const updatedRules = _.mapValues(response, (value, key) => {
        let updatedValue = value
        const category = categoryKeys.find((k) => DEFAULT_RULES[k][key])

        if (!DEFAULT_RULES[category]) return value
        if (DEFAULT_RULES[category][key]?.type === 'select') {
          const option = DEFAULT_RULES[category][key].options.find((o) => o.value === value)
          updatedValue = option
        }

        return updatedValue
      })

      reset(updatedRules, { keepDefaultValues: false })
    }

    getUpdatedData()
  }, [])

  const handleSubmit = () => {
    const data = getValues()

    // Pull out dirty fields so we only update what's necessary
    const dirtyData = _.pickBy(data, (value, key) => dirtyFields[key])

    // If no fields are dirty, don't process request
    if (_.isEmpty(dirtyData)) return

    const updatedData = { ...dirtyData }

    // Update all rule fields that have options with the matching option value
    const categoryKeys = _.keys(DEFAULT_RULES)
    _.forEach(dirtyData, (value, key) => {
      const category = categoryKeys.find((k) => DEFAULT_RULES[k][key])

      if (!DEFAULT_RULES[category]) return
      if (DEFAULT_RULES[category][key]?.type === 'select') {
        updatedData[key] = value?.value
      } else if (DEFAULT_RULES[category][key]?.type === 'date') {
        updatedData[key] = value ? dayjs(value).format('YYYY-MM-DD') : null
      } else if (
        DEFAULT_RULES[category][key]?.type === 'number' &&
        DEFAULT_RULES[category][key]?.format === 'percent'
      ) {
        updatedData[key] = value ? parseFloat(value) / 100 : null
      } else if (DEFAULT_RULES[category][key]?.type === 'number') {
        updatedData[key] = value ? parseFloat(value) : null
      }
    })

    updateGlobalRules(updatedData, handleError, setSavingRules, (m) => {
      handleSuccess(m)

      // Reset form to reset dirty fields and set new defaults
      reset(getValues(), { keepDefaultValues: false })
    })
  }

  const renderField = (field) => {
    switch (field.type) {
      case 'text': {
        return (
          <TextInput
            fullWidth
            label={field.label}
            name={field.name}
            error={errors[field.name] && 'This field is required'}
            {...register(field.name)}
            onBlur={handleSubmit}
          />
        )
      }
      case 'textarea': {
        return (
          <TextArea
            fullWidth
            label={field.label}
            name={field.name}
            error={errors[field.name] && 'This field is required'}
            {...register(field.name)}
            onBlur={handleSubmit}
          />
        )
      }
      case 'number': {
        let endIcon = null
        let startIcon = null
        if (field.format === 'percent') endIcon = <span>%</span>
        else if (field.format === 'currency') startIcon = <span>$</span>
        else if (field.format) endIcon = <span>{field.format}</span>

        return (
          <TextInput
            endIcon={endIcon}
            icon={startIcon}
            fullWidth
            label={field.label}
            name={field.name}
            type="number"
            error={errors[field.name] && 'This field is required'}
            {...register(field.name, {
              validate: (value) => {
                if (value.toString().length > 19) return 'No more than 19 digits'
                if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                return true
              },
            })}
            onBlur={handleSubmit}
          />
        )
      }
      case 'select': {
        return (
          <Controller
            name={field.name}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label={field.label}
                style={{ width: '100%' }}
                onBlur={handleSubmit}
                onChange={(option) => {
                  onChange(option)
                  clearErrors(field.name)
                }}
                options={field.options}
              />
            )}
          />
        )
      }
      case 'date': {
        return (
          <Controller
            name={field.name}
            control={control}
            render={({ field: { value } }) => (
              <CalendarInput
                label={field.label}
                id={field.name}
                error={errors[field.name] && 'This field is required'}
                value={value ? dayjs(value).toDate() : null}
                onBlur={handleSubmit}
                onChange={(d) =>
                  setValue(field.name, dayjs(d).format('YYYY-MM-DD'), { shouldDirty: true })
                }
              />
            )}
          />
        )
      }
      default:
        return null
    }
  }

  const renderCollapsedSection = (label, section) => (
    <Disclosure>
      {({ open }) => (
        <div>
          <DisclosureButton
            className={mergeClassNames(
              'text-primary group flex w-full justify-between bg-gray-50 px-4 py-2 text-left text-sm font-semibold',
              open ? 'rounded-t-lg' : 'rounded-lg',
            )}
          >
            <span>{label}</span>
            <ChevronDownIcon className="block size-6 fill-blue group-data-[open]:hidden group-data-[hover]:fill-blue-dark" />
            <ChevronUpIcon className="ml-2 hidden size-6 fill-blue group-data-[open]:block group-data-[hover]:fill-blue-dark" />
          </DisclosureButton>

          <DisclosurePanel className="text-primary rounded-b-lg bg-gray-50 p-4 text-sm">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {_.map(DEFAULT_RULES[section], (r) => renderField(r))}
            </div>
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  )

  return (
    <PageContainer withPadding>
      <StateContainer error={error} loading={loading}>
        <div className="flex size-full flex-col gap-4 overflow-y-auto">
          <div className="flex flex-row justify-between gap-4">
            <h3 className="text-xl font-semibold leading-6 text-gray-900">Global Rules</h3>

            {savingRules && (
              <div className="flex flex-row items-center gap-2">
                <div className="size-6">
                  <svg className="mr-3 size-6 motion-safe:animate-spin-slow" viewBox="0 0 24 24">
                    <CircleDashed size={24} strokeWidth={2} color="#5a5c60" />
                  </svg>
                </div>

                <span className="text-primary text-sm italic text-gray-600">
                  Saving global rules...
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-4">
            {/* Render fields in pairs of two */}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {_.map(DEFAULT_RULES.base, (r) => renderField(r))}
            </div>
          </div>

          {renderCollapsedSection('GIPS', 'gips')}
          {renderCollapsedSection('PNP', 'pnp')}
          {renderCollapsedSection('Advanced', 'advanced')}
        </div>
      </StateContainer>
    </PageContainer>
  )
}

export default GlobalRules
