import PropTypes from 'prop-types'
import React from 'react'
import { StepButton } from '../StepButton'

const StepProgress = ({ steps, activeIndex }) => (
  <nav aria-label="Progress" className="w-full">
    <ol className="divide-y divide-gray-300 overflow-x-auto rounded-md border border-gray-300 lg:flex lg:divide-y-0">
      {steps.map((step, index) => (
        <li key={step.name} className="relative lg:flex lg:flex-1">
          <StepButton
            active={index === activeIndex}
            enabled={step.enabled}
            completed={activeIndex > index}
            step={step}
          />

          {index !== steps.length - 1 && (
            <div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 lg:block">
              <svg
                fill="none"
                viewBox="0 0 22 80"
                preserveAspectRatio="none"
                className="size-full text-gray-300"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  stroke="currentcolor"
                  vectorEffect="non-scaling-stroke"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </li>
      ))}
    </ol>
  </nav>
)

export default StepProgress

StepProgress.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      enabled: PropTypes.bool,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  activeIndex: PropTypes.number.isRequired,
}
