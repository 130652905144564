import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

// Components
import { ClientHeader } from '../../components/ClientHeader'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import RulesTable from './RulesTable'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Service
import { getClient } from '../../services/clients.service'
import { getClientComposites } from '../../services/composites.service'
import { getGlobalRules } from '../../services/global.service'

const ClientRules = () => {
  // Context
  const { clientId } = useParams()
  const { canModifyData, client, setClient } = useContext(ClientDashboardStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [composites, setComposites] = useState([])
  const [globalRules, setGlobalRules] = useState(null)

  useEffect(() => {
    const getUpdatedData = async () => {
      setLoading(true)
      const [updatedClient, updatedComposites, updatedGlobalRules] = await Promise.all([
        getClient(clientId, setError, () => {}),
        getClientComposites(`/clients/${clientId}/composites?limit=99999`, setError, () => {}),
        getGlobalRules(setError, () => {}),
      ])

      if (updatedClient) {
        setClient(updatedClient)
      }

      if (updatedComposites)
        setComposites(_.map(updatedComposites.results, (c) => ({ label: c.name, value: c.id })))
      if (updatedGlobalRules) setGlobalRules(updatedGlobalRules)
      setLoading(false)
    }

    getUpdatedData()
  }, [])

  return (
    <PageContainer>
      <StateContainer error={error} loading={loading}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="flex h-[calc(100vh-150px)] w-full flex-col space-y-4 overflow-y-auto bg-white px-4 pb-8 pt-6 sm:px-6 lg:px-8">
            <RulesTable
              addLabel="Add Override"
              baseUrl={`/clients/${clientId}/rules-overrides?expand=composite`}
              clientId={clientId}
              composites={composites}
              editable={canModifyData}
              title="Composite Overrides"
            />

            <div className="flex flex-col justify-between gap-4">
              <RulesTable
                baseUrl={`/clients/${clientId}/rules?`}
                clientId={clientId}
                editable={canModifyData}
                globalRules={globalRules}
                title="Rules"
              />
            </div>
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
}

export default ClientRules
