import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

// Components
import { Button } from '../Button'

const StepComponent = ({
  backLabel = 'Go Back',
  actions,
  children,
  loadingBack = false,
  onBack = null,
}) => (
  <div className="flex size-full flex-col">
    {children}

    <div className="flex w-full justify-end gap-4 py-4 pb-24">
      {onBack && (
        <Button
          loading={loadingBack}
          onClick={onBack}
          type="button"
          label={backLabel}
          background="bg-gray"
        />
      )}

      {_.map(actions, (action) => (
        <Button
          disabled={!action.enabled}
          loading={action.loading}
          onClick={action.onClick}
          label={action.label}
          type="button"
        />
      ))}
    </div>
  </div>
)

export default StepComponent

StepComponent.propTypes = {
  backLabel: PropTypes.string,
  children: PropTypes.node,
  loadingBack: PropTypes.bool,
  actions: PropTypes.array.isRequired,
  onBack: PropTypes.func,
}
