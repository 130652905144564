import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'
import { SearchIcon } from '../SearchIcon'
import { TextInput } from '../TextInput'

const MultiSelectDropdown = ({
  className = null,
  containerClassName = null,
  customButton = null,
  disabled = false,
  id,
  label = null,
  listBoxClassName = null,
  onChange,
  options,
  openAbove = false,
  openLeft = false,
  placeholder = 'Select an Option',
  search = false,
  style = {},
  value = [],
}) => {
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  // Ref
  const searchInputRef = useRef()

  useEffect(() => {
    setFilteredOptions(options)
    setSearchTerm('')
  }, [options])

  const buttonClassNames = mergeClassNames(
    'border-gray-200 hover:border-blue bg-white-light w-full cursor-default rounded-md border-[1.5px] pr-4 py-2.5 px-3 text-left text-gray-900 shadow-sm text-sm',
    className,
    disabled && 'cursor-not-allowed text-gray-600',
  )

  const buttonTextClassNames = mergeClassNames('block truncate', disabled && 'opacity-50')

  return (
    <div style={style} className={containerClassName}>
      <Listbox onChange={onChange} value={value} disabled={disabled} multiple>
        {({ open }) => (
          <>
            {label && (
              <Label className="block pb-1 text-sm font-medium text-gray-700">{label}</Label>
            )}

            <div className="relative w-full">
              <ListboxButton
                className={mergeClassNames(
                  'w-full',
                  buttonClassNames,
                  customButton && 'w-full cursor-pointer',
                )}
                disabled={!!customButton}
                id={id}
                data-testid={id}
                onClick={(e) => e.stopPropagation()}
              >
                {customButton || (
                  <>
                    <span className={buttonTextClassNames}>
                      {value && value.length > 0
                        ? _.map(value, (v) => v.label).join(', ')
                        : placeholder}
                    </span>

                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="size-5 text-blue-800" aria-hidden="true" />
                    </span>
                  </>
                )}
              </ListboxButton>

              <Transition
                className={mergeClassNames(openAbove && 'bottom-full', openLeft && '-right-32')}
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  anchor="bottom"
                  className={mergeClassNames(
                    'absolute z-[100] mt-1 max-h-60 min-w-[250px] flex-col overflow-auto rounded-lg bg-white px-1 py-1 text-sm shadow-lg sm:text-sm',
                    customButton ? 'w-40' : 'w-[var(--button-width)]',
                    listBoxClassName,
                  )}
                  onClick={(e) => e.stopPropagation()}
                >
                  {search && (
                    <TextInput
                      autoComplete="off"
                      className="w-full rounded-lg py-2.5 pl-8 pr-2 text-sm placeholder:font-normal placeholder:text-gray-600"
                      id="search"
                      icon={<SearchIcon className="ml-1 w-4 stroke-gray" />}
                      endIcon={
                        searchTerm ? (
                          <Button
                            type="button"
                            onClick={() => {
                              setFilteredOptions(options)
                              setSearchTerm('')
                              searchInputRef.current.value = ''
                            }}
                            ariaLabel="Clear search"
                            iconOnly
                            icon={<XMarkIcon className="h-5 text-blue-800" aria-hidden="true" />}
                          />
                        ) : null
                      }
                      name="search"
                      onChange={(e) => {
                        setFilteredOptions(
                          _.filter(
                            options,
                            (o) =>
                              o.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
                              o.alwaysDisplay,
                          ),
                        )

                        setSearchTerm(e.target.value)
                      }}
                      placeholder="Search"
                      ref={searchInputRef}
                      type="text"
                      value={searchTerm}
                    />
                  )}

                  {filteredOptions.length === 0 ? (
                    <div className="flex items-start py-2">
                      <span>{search ? 'No results found.' : 'No available options.'}</span>
                    </div>
                  ) : (
                    filteredOptions.map((o) => {
                      const selected = _.find(value, (v) => v.id === o.id)
                      return (
                        <ListboxOption
                          data-testid={`check:${o.id}`}
                          id={o.id}
                          key={o.id}
                          className="group relative cursor-pointer select-none py-2 pl-3 pr-9 text-left font-medium leading-5 tracking-[0.25px] hover:bg-blue-800"
                          value={o}
                        >
                          {() => (
                            <>
                              <span className="block truncate font-normal text-black group-hover:text-white">
                                {o.label}
                              </span>

                              {selected ? (
                                <span className="text-midnight absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white">
                                  <CheckIcon className="size-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </ListboxOption>
                      )
                    })
                  )}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  customButton: PropTypes.element,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  listBoxClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  openAbove: PropTypes.bool,
  openLeft: PropTypes.bool,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.array,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
}

export default MultiSelectDropdown
