import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils & Services
import { createClientRollForwardExport } from '../../services/portfolio.service'
import { toast } from '../../utils/helpers'

const ExportRollForwardModal = ({ client, closeModal, periods }) => {
  // Context
  const { addTask } = useContext(TaskStoreContext)

  // State
  const [loading, setLoading] = useState(false)

  const {
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
    register,
  } = useForm({
    defaultValues: {
      startPeriod: periods[0],
      numOfMonths: 1,
    },
  })

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  /**
   * Handles submitting the create roll-forward export request.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const response = await createClientRollForwardExport(
      client.id,
      {
        startPeriod: data.startPeriod.value,
        numOfMonths: data.numOfMonths,
      },
      handleError,
      setLoading,
      handleSuccess,
    )

    if (response) {
      addTask({
        type: 'export',
        id: response.id,
        data: response,
        downloaded: false,
        fileName: `${client.name}_Roll-Forward_${response.startPeriod}.xlsx`,
      })
      closeModal()
    }
  }

  return (
    <Modal
      icon={<CloudArrowDownIcon className="h-6 stroke-white" />}
      open
      title="Generate Roll-Forward Export"
      loading={loading}
      onClose={closeModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex size-full flex-row gap-4 pt-2"
        >
          <Controller
            name="startPeriod"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Start Period"
                style={{ width: '100%' }}
                error={errors.startPeriod && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('startPeriod')
                }}
                options={periods}
              />
            )}
            rules={{ required: true }}
          />

          <TextInput
            fullWidth
            label="Number of Months"
            name="numOfMonths"
            type="number"
            error={errors.numOfMonths && 'This field is required'}
            min={1}
            {...register('numOfMonths', {
              required: true,
            })}
          />
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Submit',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: closeModal },
      ]}
    />
  )
}

ExportRollForwardModal.propTypes = {
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  periods: PropTypes.array.isRequired,
}

export default ExportRollForwardModal
