/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { renderSelectEditor } from '../../components/CustomEditor'
import RulePanel from './RulesPanel'

// Services, Utils & Hooks
import {
  createClientRules,
  createClientRuleOverride,
  deleteClientRules,
  getClientRules,
  updateClientRules,
  updateClientRuleOverride,
} from '../../services/rules.service'
import { toast } from '../../utils/helpers'
import { usePagination, useSorting } from '../../hooks/DataTableManagement'

import { DEFAULT_COLUMNS } from './constants'

const RulesTable = ({
  addLabel = 'Add Rule',
  baseUrl,
  clientId,
  composites = null,
  defaultSort = 'periodFrom',
  editable = true,
  globalRules = null,
  title,
}) => {
  // Pagination
  const { pagination, setTotalRecords } = usePagination(10)
  const { perPage, currentPage, totalRecords } = pagination

  // Sorting
  const { sorting } = useSorting(defaultSort)
  const { sortedColumn } = sorting

  // State
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [data, setData] = useState([])
  const [editRule, setEditRule] = useState(null)

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  const getUpdatedData = async () => {
    const response = await getClientRules(
      `${baseUrl}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      handleErrors,
      setLoading,
    )

    if (response) {
      setData(response.results)
      setTotalRecords(response.count)
    }
  }

  useEffect(() => {
    getUpdatedData()
  }, [baseUrl, perPage, currentPage, sortedColumn])

  const renderEditButton = (row) => (
    <Button
      background="bg-blue"
      label="Edit"
      onClick={() => {
        if (composites) {
          setEditRule({ type: 'override', row })
        } else {
          setEditRule({ type: 'rule', row })
        }
      }}
    />
  )

  const EDIT_RULE = {
    header: '',
    frozen: true,
    alignFrozen: 'right',
    body: renderEditButton,
    style: { minWidth: '80px' },
  }

  const COLUMNS = composites
    ? [
        {
          field: 'composite.id',
          header: 'Composite',
          body: (rowData) => rowData.composite.name,
          editor: (options) =>
            renderSelectEditor(
              composites,
              options.rowData.composite.id,
              'Select an Option',
              options,
            ),
          style: { minWidth: '200px' },
        },
        ...DEFAULT_COLUMNS,
        EDIT_RULE,
      ]
    : [...DEFAULT_COLUMNS, EDIT_RULE]

  return (
    <div className="flex flex-col justify-between gap-4">
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-xl font-semibold leading-6 text-gray-900">{title}</h3>

        {!loading && (totalRecords > 0 || composites) && (
          <Button
            background="bg-blue"
            disabled={!editable}
            label={addLabel}
            onClick={() => {
              setEditRule({ type: composites ? 'override' : 'rule', row: null })
            }}
          />
        )}

        {!loading && totalRecords === 0 && globalRules && (
          <Button
            background="bg-green"
            disabled={!editable}
            label="Import from Global Rules"
            onClick={() => {
              const clientRules = { ...globalRules }
              delete clientRules.id
              delete clientRules.createdAt
              delete clientRules.modifiedAt

              createClientRules(clientId, clientRules, handleErrors, setLoading, async () => {
                handleSuccess('Rules imported successfully.')
                getUpdatedData()
              })
            }}
          />
        )}
      </div>

      <DataTable
        data={data}
        columns={COLUMNS}
        loadingDelete={loadingDelete}
        hoverEffect={false}
        loading={loading}
        pagination={pagination}
        sorting={sorting}
        onRowDeleteConfirm={(row) => {
          deleteClientRules(clientId, row.id, handleErrors, setLoadingDelete, () => {
            handleSuccess('Rule deleted.')
            getUpdatedData()
          })
        }}
        areRowsDeleteable
        rowDeleteConfirm={() => ({
          title: 'Confirm Delete',
          type: 'warning',
          message: (
            <div className="flex flex-col">
              <span className="">Are you sure you want to delete this rule?</span>
            </div>
          ),
        })}
      />

      {editRule && (
        <RulePanel
          clientId={clientId}
          closePanel={() => {
            setEditRule(null)
            getUpdatedData()
          }}
          composites={composites}
          globalRules={globalRules}
          rule={editRule.row}
          addRule={editRule.type === 'override' ? createClientRuleOverride : createClientRules}
          updateRule={editRule.type === 'override' ? updateClientRuleOverride : updateClientRules}
        />
      )}
    </div>
  )
}

RulesTable.propTypes = {
  addLabel: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  composites: PropTypes.bool,
  defaultSort: PropTypes.string,
  editable: PropTypes.bool,
  globalRules: PropTypes.object,
  title: PropTypes.string,
}

export default RulesTable
