import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef } from 'react'

// Components
import TaskProgress from './TaskProgress'

// Context
import { TaskStoreContext } from '../../stores/TaskStore'
import { Button } from '../Button'

// Service
import {
  getClientRollForwardExport,
  getPortfolioDataImport,
} from '../../services/portfolio.service'
import { getReport } from '../../services/reports.service'

export const FINISHED_STATUSES = [
  'Ready for Review',
  'Imported',
  'Complete',
  'Failed',
  'Partially Failed',
  'Canceled',
]

const TaskProgressList = observer(() => {
  // Context
  const {
    clearTasks,
    panelOpen,
    removeTask,
    resetRefresh,
    tasks,
    togglePanel,
    triggerRefresh,
    updateTask,
  } = useContext(TaskStoreContext)

  const intervalRefs = useRef([])

  /**
   * Handles downloading a file from the completed task.
   * @param {object} task
   */
  const downloadFile = async (task) => {
    const response = await fetch(task.data.file)
    const blobUrl = await response.blob()
    const objectUrl = window.URL.createObjectURL(blobUrl)
    const downloadLink = document.createElement('a')
    downloadLink.href = objectUrl
    downloadLink.download = task.fileName
    downloadLink.target = '_blank'
    downloadLink.click()
    downloadLink.remove()
  }

  /**
   * Set up hook to trigger when a task is added, removed or when the status of a task changes.
   * - This will start a timeout task (if a `task` is not null and the interval is not already running)
   * - The interval task will check the status of the task every 3 seconds
   * - When the task is complete, the interval task will be cleared
   */
  useEffect(() => {
    const activeTasks = _.filter(
      tasks,
      (t) => t.data && !FINISHED_STATUSES.includes(t.data.status),
    )

    if (activeTasks) {
      _.forEach(activeTasks, (task) => {
        if (
          task?.data.client?.id &&
          !_.find(!intervalRefs.current, (interval) => interval.id === task.id)
        ) {
          const interval = setInterval(async () => {
            let updatedTask
            if (task.type === 'import') {
              updatedTask = await getPortfolioDataImport(task.data.client.id, task.id)
            } else if (task.type === 'export') {
              updatedTask = await getClientRollForwardExport(task.data.client.id, task.id)
            } else {
              updatedTask = await getReport(task.data.client.id, task.id)
            }
            updateTask({ ...task, data: updatedTask })
          }, 3000)

          intervalRefs.current.push({ id: task.id, interval })
        }
      })
    }

    if (triggerRefresh) resetRefresh()

    const finishedExports = _.filter(
      tasks,
      (t) => t.data && t.data.status === 'Complete' && t.type === 'export' && !t.downloaded,
    )

    if (finishedExports.length > 0) {
      _.forEach(finishedExports, (task) => {
        downloadFile(task)
        updateTask({ ...task, downloaded: true })
      })
    }

    return () => {
      _.forEach(intervalRefs.current, (i) => clearInterval(i.interval))
      intervalRefs.current = []
    }
  }, [tasks.length, triggerRefresh])

  return tasks.length > 0 ? (
    <div className="fixed bottom-3 right-3 w-96 max-w-[320px] rounded-lg bg-white pt-2 shadow-md ring-1 ring-black/5 sm:max-w-full">
      <Disclosure defaultOpen={panelOpen}>
        {() => (
          <>
            <DisclosureButton
              className="flex w-full flex-row justify-between border-b border-gray-200 px-4 pb-4 pt-2"
              onClick={togglePanel}
            >
              <div>
                <span className="inline-flex size-6 items-center justify-center rounded-full bg-blue text-xs font-bold text-white">
                  {tasks.length}
                </span>
                <span className="pl-2 font-semibold">Task Progress</span>
              </div>
              <div>
                <Button
                  onClick={() => clearTasks()}
                  type="button"
                  background="bg-red"
                  size="sm"
                  label="Clear All"
                />
              </div>
            </DisclosureButton>

            <DisclosurePanel>
              <div className="flex max-h-[300px] flex-col overflow-y-auto">
                {tasks.map(
                  (task, index) =>
                    task && (
                      <TaskProgress
                        clearTask={() => removeTask(task)}
                        loading={!FINISHED_STATUSES.includes(task.data?.status)}
                        task={task.data}
                        key={task.id}
                        isLast={index === tasks.length - 1}
                        type={task.type}
                      />
                    ),
                )}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  ) : null
})

export default TaskProgressList
