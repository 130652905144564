import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { Calendar } from 'primereact/calendar'

/**
 * Text CalendarInput
 */
const CalendarInput = forwardRef(
  (
    {
      containerClassName,
      className,
      endIcon = null,
      error = '',
      fullWidth = false,
      icon = null,
      id = '',
      label = '',
      name = '',
      placeholder = 'MM/DD/YYYY',
      value,
      onBlur = null,
      onChange,
    },
    ref,
  ) => (
    <div
      className={mergeClassNames(
        `flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`,
        containerClassName,
      )}
    >
      <label htmlFor={id} className="pb-1 text-sm font-medium text-gray-700">
        {label}
      </label>
      <div
        className={mergeClassNames(
          'relative flex w-full appearance-none rounded-md border-[1.5px] border-gray-200 bg-white px-3 py-2.5 pr-4 text-sm text-gray-900 placeholder:text-gray-500 focus-within:border-blue',
          className,
        )}
      >
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
            {icon}
          </div>
        )}

        <Calendar
          id={id}
          value={value}
          onHide={onBlur && onBlur()}
          onChange={(e) => onChange(e.value)}
          placeholder={placeholder}
          pt={{
            input: () => ({
              root: {
                className: 'w-full border-0 p-0 focus:ring-0 focus:shadow-none',
              },
            }),
          }}
          ref={ref}
          dateFormat="mm/dd/yy"
        />

        {endIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">{endIcon}</div>
        )}
      </div>

      {error && (
        <div className="mt-1 w-full bg-transparent px-2 py-1 text-center">
          <p className="text-sm font-medium text-error" id={`error:${name}`}>
            {error}
          </p>
        </div>
      )}
    </div>
  ),
)

CalendarInput.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default CalendarInput
