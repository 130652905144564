import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 * TextArea
 */
const TextArea = forwardRef(
  (
    {
      className = '',
      containerClassName = null,
      inputStyles = '',
      error = '',
      fullWidth = false,
      id = '',
      label = '',
      name = '',
      placeholder = '',
      rows = 4,
      type = 'text',
      ...rest
    },
    ref,
  ) => (
    <div
      className={mergeClassNames(
        `flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`,
        containerClassName,
      )}
    >
      <label htmlFor={id} className="pb-1 text-sm font-medium text-gray-700">
        {label}
      </label>
      <div
        className={mergeClassNames(
          'relative flex w-full appearance-none rounded-md border-[1.5px] border-gray-200 bg-white px-3 py-2.5 text-sm text-gray-900 placeholder:text-gray-500 focus-within:border-blue',
          className,
        )}
      >
        <textarea
          id={id}
          name={name}
          placeholder={placeholder}
          rows={rows}
          type={type}
          className={mergeClassNames(
            'text-primary max-h-28 min-h-5 w-full border-none bg-transparent p-0 pl-0 text-sm placeholder:text-sm placeholder:font-normal placeholder:text-gray-600 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50',
            inputStyles,
          )}
          ref={ref}
          {...rest}
        />
      </div>

      {error && (
        <div className="mt-1 w-full bg-transparent px-2 py-1 text-center">
          <p className="text-sm font-medium text-error" id={`error:${name}`}>
            {error}
          </p>
        </div>
      )}
    </div>
  ),
)

TextArea.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputStyles: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
}

export default TextArea
