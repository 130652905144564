// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the rules for the specified `clientId`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const getClientRules = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the rules with the specified `clientId` and `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createClientRules = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/clients/${clientId}/rules/`, payload)
    setSuccess('Rule updated.')
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the rules with the specified `clientId` and `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateClientRules = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(`/clients/${clientId}/rules/${payload.id}/`, payload)
    setSuccess('Rule updated.')
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the rules with the specified `clientId` and `ruleId`.
 * @param {string} clientId
 * @param {string} ruleId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteClientRules = async (
  clientId,
  ruleId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.delete(`/clients/${clientId}/rules/${ruleId}/`)
    setSuccess('Rule deleted.')
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the rules overrides for the specified `clientId`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const getClientRulesOverrides = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new rules override with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createClientRuleOverride = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/clients/${clientId}/rules-overrides/`, payload)
    setSuccess('New rule override added.')
    setLoading(false)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Updates the rule override with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateClientRuleOverride = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/clients/${clientId}/rules-overrides/${payload.id}/`,
      payload,
    )
    setSuccess('Rule override updated.')
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the rule override with the specified `ruleOverrideId`.
 * @param {string} clientId
 * @param {string} ruleOverrideId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteClientRuleOverride = async (
  clientId,
  ruleOverrideId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${clientId}/rules-overrides/${ruleOverrideId}/`)
    setLoading(false)
    setSuccess()
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
